import * as React from "react"
import { Center, Stack, Text, useColorModeValue } from "@chakra-ui/react"

import { LinkButton } from "./LinkButton"

export function Intro() {
  const color = useColorModeValue("gray.700", "gray.300")
  return (
    <Center>
      <Stack spacing={10} textAlign="center" alignItems="center">
        <Text
          as="h1"
          fontSize={{ base: "4xl", md: "5xl" }}
          fontWeight={900}
          px={{ base: 2, md: 48 }}
          lineHeight={{ base: "shorter", md: "short" }}
        >
          Welkom bij{" "}
          <Text as="span" color="yellow.500">
            Kunstbende!
          </Text>
        </Text>
        <Text
          as="h2"
          color={color}
          fontSize={{ base: "lg", md: "2xl" }}
          px={{ base: 2, md: 32 }}
          pb={2}
          lineHeight="short"
          role="main"
        >
          Ben jij tussen de 13 en 19 jaar?
          <br />
          <br />
          Doe jij iets met dans, DJ, beeldende kunst, fashion, film, muziek, schrijven of theater?
          <br />
          <br />
          En wil je het podium op?
          <br />
          <br />
          Meld je dan nu aan voor een voorronde van Kunstbende in maart/april 2024! Wie weet haal je de finale
          en krijg je een plek op een groot podium, van Oerol tot het Stedelijk Museum Amsterdam.
        </Text>

        <LinkButton
          href="/inschrijven"
          fontSize={{ base: 16, md: 22 }}
          px={8}
          py={{ base: 6, md: 8 }}
          colorScheme="yellow"
        >
          JOIN
        </LinkButton>
      </Stack>
    </Center>
  )
}
